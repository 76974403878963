import React, { Component } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { Card, CardBody, Col, CardGroup, Container, Row, CardImg } from 'reactstrap';

import { isAuthenticated } from '../../services/AuthServices';
import logo from '../../assets/img/brand/logo.png';
import sygnet from '../../assets/img/brand/sygnet.png';

import { Login, ForgotPassword, RecoverPassword, Activate } from '../../components/Auth';
//import Permiso from '../../views/Permisos/Permiso';

class AuthLayout extends Component {
    render() {  
      
        const { from } = this.props.location.state || { from: { pathname: '/' } }
        
        if (isAuthenticated()) {
            return (<Redirect to={from} />);
        }

        return (
            <div className="app flex-row align-items-center">
                <Container>
                    <Row className="justify-content-center">
                        <Col md="6">
                            <CardGroup>
                                <Card className="pt-4 pl-2 pr-2">
                                    <Container style={{ textAlign: "center" }}>
                                        <CardImg src={logo} alt="Card image cap" className="card-img-width" style={{width: "10em"}} />
                                        {/*<p style={{color: "#0c4ca4"}}><strong>Sistema de Control de Stock</strong></p>*/}
                                    </Container>
                                    <CardBody>
                                        <Container>
                                        <Switch>
                                            <Route path="/auth" exact component={Login} />
                                            <Route path='/auth/activate/:activationKey' component={Activate} />
						                    <Route path='/auth/remember' component={ForgotPassword} />
                                            <Route path='/auth/recover/:recoverKey' component={RecoverPassword} />
                                        </Switch>
                                        <hr />
                                        <Col md="12" className="text-right p-0">
                                            {/*<span style={{color: "#1e90ff"}}>Smart 360</span> &nbsp;*/}
                                            <CardImg src={sygnet} className="card-img-width" style={{ width: "2.5em"}}/>
                                        </Col>
                                        </Container>

                                    </CardBody>
                                </Card>
                            </CardGroup>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}

export default AuthLayout;