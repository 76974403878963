import React, { Component } from 'react';

class Activate extends Component {
    /*constructor(props) {
        super(props); 
    }*/

    render() {
        return <p>En construcción: Activate</p>;
    }
}

export default Activate;
