import React, { Component } from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
// import { renderRoutes } from 'react-router-config';
import './App.scss';
import { setConfig } from '../utils/Storage';
import { checkSession } from '../services/AuthServices';
import PrivateRoute from '../hoc/PrivateRoute';
import { DefaultLayout, AuthLayout } from '../containers';

const loading = () => <div className="animated fadeIn pt-3 text-center">Loading...</div>;

class App extends Component {

  constructor(props){
    super(props);

    this.state = {
      authVerified: null
    }
  }

  componentDidMount(){
    this.getConfig()
    .then(() => {
      checkSession()
      .then((result) => {
        this.setState({authVerified: result});
      });
    });
  }

  getConfig = () => {
    return fetch('./config/config.json', {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    })
    .then(response => response.json())
    .then(configData => {
      setConfig(configData);
    })
    .catch(function(error){
		console.log(error);
	})
  }

  render() {
    return (
      <HashRouter>
          <React.Suspense fallback={loading()}>
            <Switch>
              <Route path="/auth" name="Home" component={AuthLayout} />
              <PrivateRoute path="/" name="Home" component={DefaultLayout} />
            </Switch>
          </React.Suspense>
      </HashRouter>
    );
  }
}

export default App;
