import React, { Component } from 'react';
import { Button, Col, Form, Input, InputGroup, InputGroupAddon, InputGroupText, Row } from 'reactstrap';
import FormValidation from '../../utils/FormValidation';
import Validator from '../../utils/Validator';
import { user, recoverPassword } from '../../services/AuthServices';

class RecoverPassword extends Component {
    constructor(props) {
        super(props);

        this.state = {
            password: '',
            confirmationPassword: '',
            recoverKey: props.match.params.recoverKey,
            keyValidated: false,
            keyValid: false,
            passwordChanged: false
        };

        this.formValidation = new FormValidation({
            component: this,
            validators: {
                'password': (value) => Validator.notEmpty(value),
                'confirmationPassword': (value) => Validator.equalNotEmpty(value, this.state.password)
            }
        });
    }

    handleChangePassword = event => {
        const value = event.target.value;
        this.setState({
            password: value,
            tryLogin: false
        });
    }

    handleChangeConfirmationPassword = event => {
        const value = event.target.value;
        this.setState({
            confirmationPassword: value,
            tryLogin: false
        });
    }

    componentDidMount() {
        const actualState = { ...this.state };
        user(actualState.recoverKey)
            .catch(() => {
                actualState.keyValid = false;
                actualState.keyValidated = true;
                this.setState(actualState);
            })
            .then(response => {
                actualState.keyValidated = true;
                actualState.keyValid = response.status === 200;
                this.setState(actualState);
            });
    }

    handleSubmit = (event) => {
        event.preventDefault();

        recoverPassword(this.state.recoverKey, this.state.password)
            .catch(() => {
                this.setState({
                    passwordChanged: false,
                });
            })
            .then((response) => {
                this.setState({
                    passwordChanged: response.status === 200
                });
            })
    }

    render() {

        if (!this.state.keyValidated)
            return null;

        this.formValidation.validate();
        const validationState = this.formValidation.state;

        const body = !this.state.keyValid ? (
            <React.Fragment>
                <p className="text-center">La clave de recuperación de contraseña no es válida</p>
            </React.Fragment>
        ) : !this.state.passwordChanged ? (
            <React.Fragment>
                <p className="text-muted">Ingrese su nueva contraseña</p>
                <InputGroup className="mb-4">
                    <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                            <i className="icon-lock"></i>
                        </InputGroupText>
                    </InputGroupAddon>
                    <Input
                        type="password"
                        placeholder="Contraseña"
                        autoComplete="contrasena-nueva"
                        value={this.state.password}
                        onChange={this.handleChangePassword}
                        valid={!validationState.password.pristine && validationState.password.valid}
                        invalid={!validationState.password.pristine && !validationState.password.valid} />
                </InputGroup>
                <InputGroup className="mb-4">
                    <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                            <i className="icon-lock"></i>
                        </InputGroupText>
                    </InputGroupAddon>
                    <Input
                        type="password"
                        placeholder="Confirmar contraseña"
                        autoComplete="contrasena-nueva"
                        value={this.state.confirmationPassword}
                        onChange={this.handleChangeConfirmationPassword}
                        valid={!validationState.confirmationPassword.pristine && validationState.confirmationPassword.valid}
                        invalid={!validationState.confirmationPassword.pristine && !validationState.confirmationPassword.valid} />
                </InputGroup>
                {this.state.tryForgotPassword && this.state.forgotPasswordError.length > 0 && <p>{this.state.forgotPasswordError}</p>}
                <Row>
                    <Col md="12">
                        <Button
                            color="primary"
                            className="px-4 btn-custom-block"
                            type="submit"
                            disabled={!validationState.form.valid}>Modificar</Button>
                    </Col>
                </Row>
            </React.Fragment>
        ) : (
                    <React.Fragment>
                        <p className="text-center">Su contraseña ha sido modificada.</p>
                    </React.Fragment>
                );

        return (<Form className="recoverPassword" onSubmit={this.handleSubmit}>
            <h1>Recuperar contraseña</h1>
            {body}
        </Form>);
    }
}

export default RecoverPassword;
