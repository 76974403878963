import React from 'react';

/*const Breadcrumbs = React.lazy(() => import('./views/Base/Breadcrumbs'));
const Cards = React.lazy(() => import('./views/Base/Cards'));
const Carousels = React.lazy(() => import('./views/Base/Carousels'));
const Collapses = React.lazy(() => import('./views/Base/Collapses'));
const Dropdowns = React.lazy(() => import('./views/Base/Dropdowns'));
const Forms = React.lazy(() => import('./views/Base/Forms'));
const Jumbotrons = React.lazy(() => import('./views/Base/Jumbotrons'));
const ListGroups = React.lazy(() => import('./views/Base/ListGroups'));
const Navbars = React.lazy(() => import('./views/Base/Navbars'));
const Navs = React.lazy(() => import('./views/Base/Navs'));
const Paginations = React.lazy(() => import('./views/Base/Paginations'));
const Popovers = React.lazy(() => import('./views/Base/Popovers'));
const ProgressBar = React.lazy(() => import('./views/Base/ProgressBar'));
const Switches = React.lazy(() => import('./views/Base/Switches'));
const Tables = React.lazy(() => import('./views/Base/Tables'));
const Tabs = React.lazy(() => import('./views/Base/Tabs'));
const Tooltips = React.lazy(() => import('./views/Base/Tooltips'));
const BrandButtons = React.lazy(() => import('./views/Buttons/BrandButtons'));
const ButtonDropdowns = React.lazy(() => import('./views/Buttons/ButtonDropdowns'));
const ButtonGroups = React.lazy(() => import('./views/Buttons/ButtonGroups'));
const Buttons = React.lazy(() => import('./views/Buttons/Buttons'));
const Charts = React.lazy(() => import('./views/Charts'));
const CoreUIIcons = React.lazy(() => import('./views/Icons/CoreUIIcons'));
const Flags = React.lazy(() => import('./views/Icons/Flags'));
const FontAwesome = React.lazy(() => import('./views/Icons/FontAwesome'));
const SimpleLineIcons = React.lazy(() => import('./views/Icons/SimpleLineIcons'));
const Alerts = React.lazy(() => import('./views/Notifications/Alerts'));
const Badges = React.lazy(() => import('./views/Notifications/Badges'));
const Modals = React.lazy(() => import('./views/Notifications/Modals'));
const Colors = React.lazy(() => import('./views/Theme/Colors'));
const Typography = React.lazy(() => import('./views/Theme/Typography'));
const Widgets = React.lazy(() => import('./views/Widgets/Widgets'));
const Users = React.lazy(() => import('./views/Users/Users'));
const User = React.lazy(() => import('./views/Users/User'));*/

const Dashboard = React.lazy(() => import ('./views/Dashboard'));

const Articulos = React.lazy(() => import ('./views/Configuracion/Articulo/Articulos'));
const Articulo = React.lazy(() => import ('./views/Configuracion/Articulo/Articulo'));

const Marcas = React.lazy(() => import ('./views/Configuracion/Marca/Marcas'));
const Marca = React.lazy(() => import ('./views/Configuracion/Marca/Marca'));

const Proveedores = React.lazy(() => import ('./views/Configuracion/Proveedor/Proveedores'));
const Proveedor = React.lazy(() => import ('./views/Configuracion/Proveedor/Proveedor'));

const Clientes = React.lazy(() => import ('./views/Configuracion/Cliente/Clientes'));
const Cliente = React.lazy(() => import ('./views/Configuracion/Cliente/Cliente'));

const Compras = React.lazy(() => import ('./views/Operaciones/Compra/Compras'));
const Compra = React.lazy(() => import ('./views/Operaciones/Compra/Compra'));

const Ventas = React.lazy(() => import ('./views/Operaciones/Venta/Ventas'));
const Venta = React.lazy(() => import ('./views/Operaciones/Venta/Venta'));

const Pago = React.lazy(() => import ('./views/Operaciones/Pagos/Pago'));

const Usuarios = React.lazy(() => import ('./views/Administracion/Usuario/Usuarios'));
const Usuario = React.lazy(() => import ('./views/Administracion/Usuario/Usuario'));

const Permiso = React.lazy(() => import('./views/Permisos/Permiso'));

const ListaDePrecios = React.lazy(() => import('./views/Reportes/ListaDePrecios'));

const Actualizaciones = React.lazy(() => import('./views/Operaciones/Actualizaciones/Actualizaciones'));

const Pedidos = React.lazy(() => import('./views/Operaciones/Pedidos/Pedidos'));
const Pedido = React.lazy(() => import('./views/Operaciones/Pedidos/Pedido'));
const PedidoVer = React.lazy(() => import('./components/Pedidos/PedidosView'));

// https://github.com/ReactTraining/react-router/tree/master/packages/react-router-config
const routes = [
    { path: '/', exact: true, name: 'Home' },
    { path: '/dashboard', name: 'Dashboard', component: Dashboard },

    { path: '/nuevo/articulo', exact: true, name: 'Articulo', component: Articulo },
    { path: '/modificar/articulo/:id', exact: true, name: 'Articulo', component: Articulo },
    { path: '/articulos', exact: true, name: 'Articulos', component: Articulos, permissions: ['ARTICULOS_LISTAR']  },

    { path: '/nueva/marca', exact: true, name: 'Marca', component: Marca },
    { path: '/modificar/marca/:id', exact: true, name: 'Marca', component: Marca },
    { path: '/marcas', exact: true, name: 'Marcas', component: Marcas, permissions: ['MARCAS_LISTAR'] },

    { path: '/nuevo/proveedor', exact: true, name: 'Proveedor', component: Proveedor },
    { path: '/modificar/proveedor/:id', exact: true, name: 'Proveedor', component: Proveedor },
    { path: '/proveedores', exact: true, name: 'Proveedores', component: Proveedores, permissions: ['PROVEEDORES_LISTAR']  },

    { path: '/nuevo/cliente', exact: true, name: 'Cliente', component: Cliente },
    { path: '/modificar/cliente/:id', exact: true, name: 'Cliente', component: Cliente },
    { path: '/clientes', exact: true, name: 'Clientes', component: Clientes, permissions: ['CLIENTES_LISTAR']  },

    { path: '/nueva/compra', exact: true, name: 'Compra', component: Compra },
    { path: '/modificar/compra/:id', exact: true, name: 'Compra', component: Compra },
    { path: '/compras', exact: true, name: 'Compras', component: Compras, permissions: ['COMPRAS_LISTAR']  },

    { path: '/nueva/venta', exact: true, name: 'Venta', component: Venta },
    { path: '/modificar/venta/:id', exact: true, name: 'Venta', component: Venta },
	{ path: '/ventas', exact: true, name: 'Ventas', component: Ventas, permissions: ['VENTAS_LISTAR']  },

	{ path: '/pago', exact: true, name: 'Pago', component: Pago },
    
    { path: '/nuevo/usuario', exact: true, name: 'Usuario', component: Usuario },
    { path: '/modificar/usuario/:id', exact: true, name: 'Usuario', component: Usuario },
    { path: '/usuarios', exact: true, name: 'Usuarios', component: Usuarios, permissions: ['USUARIOS_LISTAR'] },

    { path: '/permiso', exact: true, name: 'Permiso Denegado', component: Permiso, permissions: [] },

    { path: '/listaDePrecios', exact: true, name: 'Lista de Precios', component: ListaDePrecios, permissions: ['REPORTES_LISTAR'] },

    { path: '/actualizaciones', exact: true, name: 'Actualizaciones', component: Actualizaciones, permissions: ['REPORTES_LISTAR'] },

    { path: '/nuevo/pedido', exact: true, name: 'Pedido', component: Pedido },
    { path: '/pedidos', exact: true, name: 'Pedidos', component: Pedidos, permissions: ['REPORTES_LISTAR'] },
    { path: '/ver/pedidos/:id', exact: true, name: 'Ver Pedido', component: PedidoVer }

    /*{ path: '/theme', exact: true, name: 'Theme', component: Colors },
    { path: '/theme/colors', name: 'Colors', component: Colors },
    { path: '/theme/typography', name: 'Typography', component: Typography },
    { path: '/base', exact: true, name: 'Base', component: Cards },
    { path: '/base/cards', name: 'Cards', component: Cards },
    { path: '/base/forms', name: 'Forms', component: Forms },
    { path: '/base/switches', name: 'Switches', component: Switches },
    { path: '/base/tables', name: 'Tables', component: Tables },
    { path: '/base/tabs', name: 'Tabs', component: Tabs },
    { path: '/base/breadcrumbs', name: 'Breadcrumbs', component: Breadcrumbs },
    { path: '/base/carousels', name: 'Carousel', component: Carousels },
    { path: '/base/collapses', name: 'Collapse', component: Collapses },
    { path: '/base/dropdowns', name: 'Dropdowns', component: Dropdowns },
    { path: '/base/jumbotrons', name: 'Jumbotrons', component: Jumbotrons },
    { path: '/base/list-groups', name: 'List Groups', component: ListGroups },
    { path: '/base/navbars', name: 'Navbars', component: Navbars },
    { path: '/base/navs', name: 'Navs', component: Navs },
    { path: '/base/paginations', name: 'Paginations', component: Paginations },
    { path: '/base/popovers', name: 'Popovers', component: Popovers },
    { path: '/base/progress-bar', name: 'Progress Bar', component: ProgressBar },
    { path: '/base/tooltips', name: 'Tooltips', component: Tooltips },
    { path: '/buttons', exact: true, name: 'Buttons', component: Buttons },
    { path: '/buttons/buttons', name: 'Buttons', component: Buttons },
    { path: '/buttons/button-dropdowns', name: 'Button Dropdowns', component: ButtonDropdowns },
    { path: '/buttons/button-groups', name: 'Button Groups', component: ButtonGroups },
    { path: '/buttons/brand-buttons', name: 'Brand Buttons', component: BrandButtons },
    { path: '/icons', exact: true, name: 'Icons', component: CoreUIIcons },
    { path: '/icons/coreui-icons', name: 'CoreUI Icons', component: CoreUIIcons },
    { path: '/icons/flags', name: 'Flags', component: Flags },
    { path: '/icons/font-awesome', name: 'Font Awesome', component: FontAwesome },
    { path: '/icons/simple-line-icons', name: 'Simple Line Icons', component: SimpleLineIcons },
    { path: '/notifications', exact: true, name: 'Notifications', component: Alerts },
    { path: '/notifications/alerts', name: 'Alerts', component: Alerts },
    { path: '/notifications/badges', name: 'Badges', component: Badges },
    { path: '/notifications/modals', name: 'Modals', component: Modals },
    { path: '/widgets', name: 'Widgets', component: Widgets },
    { path: '/charts', name: 'Charts', component: Charts },
    { path: '/users', exact: true,  name: 'Users', component: Users },
    { path: '/users/:id', exact: true, name: 'User Details', component: User },*/
];

export default routes;
