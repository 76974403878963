import React, { Component } from 'react';
import { Button, Col, Form, Input, InputGroup, InputGroupAddon, InputGroupText, Row } from 'reactstrap';
import FormValidation from '../../utils/FormValidation';
import Validator from '../../utils/Validator';
import { forgotPassword } from '../../services/AuthServices';

class ForgotPassword extends Component {
    constructor(props) {
        super(props);

        this.state = {
            mail: '',
            tryForgotPassword: false,
            forgotPasswordError: '',
            emailSent: false
        };

        this.formValidation = new FormValidation({
            component: this,
            validators: {
                'mail': (value) => Validator.notEmpty(value)
            }
        });
    }

    handleChangeMail = event => {
        const value = event.target.value;
        this.setState({
            mail: value,
            tryForgotPassword: false
        });
    }

    handleSubmit = (event) => {
        event.preventDefault();

        forgotPassword(this.state.mail)
            .catch(() => {
                this.setState({
                    forgotPasswordError: "Se ha producido un error. Intente nuevamente",
                    tryForgotPassword: true,
                });
            })
            .then((response) => {

                if(response.status === 200) {
                    this.setState({
                        emailSent: true,
                        forgotPasswordError: '',
                        tryForgotPassword: true
                    });
                } else {
                    if(response.status === 400) {
                        response.json()
                        .then(data => {
                            this.setState({
                                forgotPasswordError: data.mensaje,
                                tryForgotPassword: true,
                            });
                        }).catch(() => {
                            this.setState({
                                forgotPasswordError: "Se ha producido un error. Intente nuevamente",
                                tryForgotPassword: true,
                            });
                        })
                    } else {
                        this.setState({
                            forgotPasswordError: "Se ha producido un error. Intente nuevamente",
                            tryForgotPassword: true,
                        });
                    }
                }
            });
    }

    render() {
        this.formValidation.validate();
        const validationState = this.formValidation.state;

        const body = !this.state.emailSent ? (
            <React.Fragment>
                <p className="text-muted">Ingrese su mail</p>
                <InputGroup className="mb-3">
                    <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                            <i className="icon-user"></i>
                        </InputGroupText>
                    </InputGroupAddon>
                    <Input
                        type="text"
                        placeholder="Mail"
                        autoComplete="mail"
                        value={this.state.mail}
                        onChange={this.handleChangeMail}
                        valid={!validationState.mail.pristine && validationState.mail.valid}
                        invalid={!validationState.mail.pristine && !validationState.mail.valid} />
                </InputGroup>
                {this.state.tryForgotPassword && this.state.forgotPasswordError.length > 0 && <p>{this.state.forgotPasswordError}</p>}
                <Row>
                    <Col md="12">
                        <Button
                            color="primary"
                            className="px-4 btn-custom-block"
                            type="submit"
                            disabled={!validationState.form.valid}>Recordar</Button>
                    </Col>
                </Row>
            </React.Fragment>
        ) : (
            <React.Fragment>
                <p className="text-center">Verifique su casilla de e-mail. Le enviamos las instrucciones para cambiar su contraseña.</p>
            </React.Fragment>
        );

        return (<Form className="forgotPassword" onSubmit={this.handleSubmit}>
            <h1>Recordar contraseña</h1>
            {body}
        </Form>);
    }
}

export default ForgotPassword;
