import React, { Component, Suspense } from 'react';
import { Redirect, /*Route,*/ Switch } from 'react-router-dom';
import { Container } from 'reactstrap';

import {
  AppAside,
  AppBreadcrumb,
  AppFooter,
  AppHeader,
  AppSidebar,
  //AppSidebarFooter,
  //AppSidebarForm,
  AppSidebarHeader,
  //AppSidebarMinimizer,
  AppSidebarNav,
} from '@coreui/react';
// sidebar nav config
import navigation from '../../_nav';
// routes config
import routes from '../../routes';
import PrivateRoute from '../../hoc/PrivateRoute';

import { hasAllPermissions, hasAnyPermission } from '../../utils/ValidatePermissions';

const DefaultAside = React.lazy(() => import('./DefaultAside'));
const DefaultFooter = React.lazy(() => import('./DefaultFooter'));
const DefaultHeader = React.lazy(() => import('./DefaultHeader'));

class DefaultLayout extends Component {

	loading = () => <div className="animated fadeIn pt-1 text-center">Loading...</div>

	signOut(e) {
		e.preventDefault()
		this.props.history.push('/login')
	}

	render() {
		const { items } = navigation;
		const customNavigation = { items: [] };

		items.forEach((item, index) => {
			if(item && (!item.permissions || (item.title && hasAnyPermission(item.permissions)) || hasAllPermissions(item.permissions)))
				customNavigation.items.push(item);
		});

		return (
		<div className="app">
			<AppHeader fixed>
			<Suspense  fallback={this.loading()}>
				<DefaultHeader onLogout={e=>this.signOut(e)}/>
			</Suspense>
			</AppHeader>
			<div className="app-body">
				{customNavigation && customNavigation.items && customNavigation.items.length > 1 && (
					<AppSidebar fixed display="lg">
						<AppSidebarHeader fixed display="lg" />
							{/*<AppSidebarForm />
							<Suspense>*/}
							<AppSidebarNav navConfig={customNavigation} {...this.props} />
							{/*</Suspense>
							<AppSidebarFooter />
							<AppSidebarMinimizer />*/}
						</AppSidebar>
					)}
			<main className="main">
				<AppBreadcrumb appRoutes={routes}/>
				<Container fluid>
				<Suspense fallback={this.loading()}>
					<Switch>
					{routes.map((route, idx) => {
						return route.component ? (
						<PrivateRoute
							key={idx}
							path={route.path}
							exact={route.exact}
							name={route.name}
							component={route.component}
							permissions={route.permissions}
						/>
						) : (null);
					})}
					<Redirect from="/" to="/dashboard" />
					</Switch>
				</Suspense>
				</Container>
			</main>
			<AppAside fixed>
				<Suspense fallback={this.loading()}>
				<DefaultAside />
				</Suspense>
			</AppAside>
			</div>
			<AppFooter>
			<Suspense fallback={this.loading()}>
				<DefaultFooter />
			</Suspense>
			</AppFooter>
		</div>
		);
	}
}

export default DefaultLayout;
